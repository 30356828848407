<template>
  <Card>
    <template #title>
      {{ $t("applications.companyInformation") }}
    </template>

    <template #content>
      <div class="grid">
        <div class="col-12">
          <div class="grid align-items-center grid-nogutter">
            <div class="col-12 md:col-2 lg:col-2 align-self-center">
              <label for="companyName"
                >{{ $t("applications.companyName") }} *:</label
              >
            </div>
            <div class="col-12 md:col-7 lg:col-6">
              <div class="field p-fluid">
                <InputText
                  id="companyName"
                  :disabled="information.submittedApplication"
                  :class="{
                    'p-invalid':
                      v$.information.companyName.$invalid && submitted,
                  }"
                  v-model="v$.information.companyName.$model"
                  aria-describedby="companyName-error"
                />
              </div>
            </div>
            <ul
              class="p-error text-xs col-10 col-offset-0 md:col-offset-2 pl-3"
              v-if="v$.information.companyName.$error && submitted"
            >
              <li
                v-for="(error, index) of v$.information.companyName.$errors"
                :key="index"
                class="w-20rem"
              >
                {{
                  error.$message.replace(
                    "[value]",
                    $t("applications.companyName")
                  )
                }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12">
          <div class="grid align-items-center grid-nogutter">
            <div class="col-12 md:col-2 lg:col-2 align-self-center">
              <label for="profession"
                >{{ $t("applications.profession") }} *:</label
              >
            </div>
            <div class="col-12 md:col-7 lg:col-6">
              <div class="field p-fluid">
                <InputText
                  id="profession"
                  :disabled="information.submittedApplication"
                  :class="{
                    'p-invalid':
                      v$.information.profession.$invalid && submitted,
                  }"
                  v-model="v$.information.profession.$model"
                  aria-describedby="profession-error"
                />
              </div>
            </div>
            <ul
              class="p-error text-xs col-10 col-offset-0 md:col-offset-2 pl-3"
              v-if="v$.information.profession.$error && submitted"
            >
              <li
                v-for="(error, index) of v$.information.profession.$errors"
                :key="index"
              >
                {{
                  error.$message.replace(
                    "[value]",
                    $t("applications.profession")
                  )
                }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12">
          <div class="grid align-items-center grid-nogutter">
            <div class="col-12 md:col-2 lg:col-2 align-self-center">
              <label for="companyType"
                >{{ $t("applications.companyType") }} *:</label
              >
            </div>
            <div class="col-12 md:col-7 lg:col-6">
              <div class="field p-fluid">
                <Dropdown
                  id="companyType"
                  :loading="loadingDropdown"
                  :disabled="information.submittedApplication"
                  :class="{
                    'p-invalid':
                      v$.information.companyType.$invalid && submitted,
                  }"
                  v-model="v$.information.companyType.$model"
                  :options="companyTypes"
                  optionValue="branch"
                  optionLabel="branch"
                  panelClass="overlay"
                  :filter="true"
                  dataKey="id"
                >
                  <template #option="slotProps">
                    <div class="criteria-item">
                      <div>{{ slotProps.option.branch }}</div>
                    </div>
                  </template>
                </Dropdown>
                <!-- <InputText
                  id="companyType"
                  :disabled="information.submittedApplication"
                  :class="{
                    'p-invalid':
                      v$.information.companyType.$invalid && submitted,
                  }"
                  v-model="v$.information.companyType.$model"
                /> -->
              </div>
            </div>
            <ul
              class="p-error text-xs col-10 col-offset-0 md:col-offset-2 pl-3"
              v-if="v$.information.companyType.$error && submitted"
            >
              <li
                v-for="(error, index) of v$.information.companyType.$errors"
                :key="index"
              >
                {{
                  error.$message.replace(
                    "[value]",
                    $t("applications.companyType")
                  )
                }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12">
          <div class="grid align-items-center grid-nogutter">
            <div class="col-12 md:col-2 lg:col-2 align-self-center">
              <label for="vat">{{ $t("applications.vat") }} *:</label>
            </div>
            <div class="col-12 md:col-7 lg:col-6">
              <div class="field p-fluid">
                <InputText
                  id="vat"
                  :disabled="information.submittedApplication"
                  :class="{
                    'p-invalid': v$.information.vat.$invalid && submitted,
                  }"
                  v-model="v$.information.vat.$model"
                  aria-describedby="vat-error"
                />
              </div>
            </div>
            <ul
              class="p-error text-xs col-10 col-offset-0 md:col-offset-2 pl-3"
              v-if="v$.information.vat.$error && submitted"
            >
              <li
                v-for="(error, index) of v$.information.vat.$errors"
                :key="index"
              >
                {{ error.$message.replace("[value]", $t("applications.vat")) }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12">
          <div class="grid align-items-center grid-nogutter">
            <div class="col-12 md:col-2 lg:col-2 align-self-center">
              <label for="doy">{{ $t("applications.doy") }} *:</label>
            </div>
            <div class="col-12 md:col-7 lg:col-6">
              <div class="field p-fluid">
                <Dropdown
                  id="doy"
                  :loading="loadingDropdown"
                  :disabled="information.submittedApplication"
                  :class="{
                    'p-invalid': v$.information.doy.$invalid && submitted,
                  }"
                  v-model="v$.information.doy.$model"
                  :options="doyOptions"
                  optionValue="name"
                  optionLabel="name"
                  :filter="true"
                  dataKey="id"
                />
                <!-- <InputText
                  id="doy"
                  :disabled="information.submittedApplication"
                  :class="{
                    'p-invalid': v$.information.doy.$invalid && submitted,
                  }"
                  v-model="v$.information.doy.$model"
                /> -->
              </div>
            </div>
            <ul
              class="p-error text-xs col-10 col-offset-0 md:col-offset-2 pl-3"
              v-if="v$.information.doy.$error && submitted"
            >
              <li
                v-for="(error, index) of v$.information.doy.$errors"
                :key="index"
              >
                {{ error.$message.replace("[value]", $t("applications.doy")) }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 md:col-5">
          <div class="grid">
            <div class="col-12">
              <div class="grid align-items-center grid-nogutter">
                <div class="col-12 md:col-5 align-self-center">
                  <label for="address"
                    >{{ $t("applications.address") }} *:</label
                  >
                </div>
                <div class="col-12 md:col-7 lg:col-7 pl-0">
                  <div class="field p-fluid">
                    <!-- <Dropdown
                      id="country"
                      :loading="loadingDropdown"
                      :disabled="true"
                      :class="{
                        'p-invalid':
                          v$.information.address.country.$invalid &&
                          (submitted || validateDate),
                      }"
                      v-model="v$.information.address.country.$model"
                      :options="countries"
                      optionValue="nameGreek"
                      optionLabel="nameGreek"
                      @change="resetValidation"
                    /> -->
                    <AutoComplete
                      id="address"
                      v-model="locationSearchValue"
                      :suggestions="locationResults"
                      @complete="validateAddress($event)"
                      field="formatted_address"
                      @item-select="selectLocation"
                      :disabled="information.submittedApplication"
                    >
                    </AutoComplete>
                    <!-- <InputText
                  id="country"
                  :disabled="information.submittedApplication"
                  :class="{
                    'p-invalid':
                      v$.information.address.country.$invalid && submitted,
                  }"
                  v-model="v$.information.address.country.$model"
                /> -->
                  </div>
                </div>
                <ul
                  class="p-error text-xs col-7 col-offset-0 md:col-offset-5 pl-3"
                  v-if="
                    (v$.information.latitude.$error ||
                      v$.information.longitude.$error ||
                      v$.locationSearchValue.$error) &&
                    (submitted || validateDate)
                  "
                >
                  <li>
                    {{ $t("applications.invalidAddress") }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 md:col-4 lg:col-3 p-0">
          <div id="map" style="width: 100%; height: 30vh"></div>
        </div>
        <!-- <div
          class="col-12 col-offset-0 md:col-offset-2 md:col-6 field p-fluid px-1"
        >
          <Button
            id="validateButton"
            :label="$t('applications.validateAddress')"
            @click="validateAddress"
            iconPos="right"
            :loading="loadingValidation"
            v-if="!information.submittedApplication"
          >
          </Button>
        </div> -->
        <div class="col-12">
          <div class="grid align-items-center grid-nogutter">
            <div class="col-12 md:col-2 lg:col-2 align-self-center">
              <label for="email">{{ $t("applications.email") }} *:</label>
            </div>
            <div class="col-12 md:col-7 lg:col-6">
              <div class="field p-fluid">
                <InputText
                  id="email"
                  :disabled="information.submittedApplication"
                  :class="{
                    'p-invalid': v$.information.email.$invalid && submitted,
                  }"
                  v-model="v$.information.email.$model"
                  aria-describedby="email-error"
                />
              </div>
            </div>
            <ul
              v-if="v$.information.email.$error && submitted"
              class="p-error text-xs col-10 col-offset-0 md:col-offset-2 pl-3"
            >
              <li
                v-for="(error, index) of v$.information.email.$errors"
                :key="index"
              >
                {{
                  error.$message.replace("[value]", $t("applications.email"))
                }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12">
          <div class="grid align-items-center grid-nogutter">
            <div class="col-12 md:col-2 lg:col-2 align-self-center">
              <label for="telephone"
                >{{ $t("applications.telephone") }} *:</label
              >
            </div>
            <div class="col-12 md:col-7 lg:col-6">
              <div class="field p-fluid">
                <!-- <span class="p-inputgroup-addon">+30</span> -->
                <InputText
                  id="telephone"
                  :disabled="information.submittedApplication"
                  :class="{
                    'p-invalid':
                      v$.information.contact.telephone.$invalid && submitted,
                  }"
                  v-model="v$.information.contact.telephone.$model"
                  aria-describedby="telephone-error"
                />
              </div>
            </div>
            <ul
              class="p-error text-xs col-10 col-offset-0 md:col-offset-2 pl-3"
              v-if="v$.information.contact.telephone.$error && submitted"
            >
              <li
                v-for="(error, index) of v$.information.contact.telephone
                  .$errors"
                :key="index"
              >
                {{
                  error.$message.replace(
                    "[value]",
                    $t("applications.telephone")
                  )
                }}
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="col">
          <div class="grid align-items-center grid-nogutter">
            <div class="col-12 md:col-2 lg:col-2 align-self-center">
              <label for="fax">{{ $t("applications.fax") }} *:</label>
            </div>
            <div class="col-12 md:col-7 lg:col-6">
              <div class="field p-fluid">
                <InputText
                  id="fax"
                  :disabled="information.submittedApplication"
                  :class="{
                    'p-invalid':
                      v$.information.contact.fax.$invalid && submitted,
                  }"
                  v-model="v$.information.contact.fax.$model"
                  aria-describedby="fax-error"
                />
              </div>
            </div>
            <ul
              v-if="v$.information.contact.fax.$error && submitted"
              class="p-error text-xs col-10 col-offset-0 md:col-offset-2 pl-3"
            >
              <li
                v-for="(error, index) of v$.information.contact.fax.$errors"
                :key="index"
              >
                {{ error.$message.replace("[value]", $t("applications.fax")) }}
              </li>
            </ul>
          </div>
        </div> -->
        <div class="col-12">
          <div class="grid align-items-center grid-nogutter">
            <div class="col-12 md:col-2 lg:col-2 align-self-center">
              <label for="website">{{ $t("applications.website") }} :</label>
            </div>
            <div class="col-12 md:col-7 lg:col-6">
              <div class="field p-fluid">
                <InputText
                  id="website"
                  :disabled="information.submittedApplication"
                  :class="{
                    'p-invalid':
                      v$.information.contact.website.$invalid && submitted,
                  }"
                  v-model="v$.information.contact.website.$model"
                  aria-describedby="website-error"
                />
              </div>
            </div>
            <ul
              v-if="v$.information.contact.website.$error && submitted"
              class="p-error text-xs col-10 col-offset-0 md:col-offset-2 pl-3"
            >
              <li
                v-for="(error, index) of v$.information.contact.website.$errors"
                :key="index"
              >
                {{
                  error.$message.replace("[value]", $t("applications.website"))
                }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12">
          <div class="grid vertical-container align-items-center grid-nogutter">
            <div class="col-8 md:col-4 lg:col-4 align-self-center">
              <i for="notify">{{ $t("applications.notify") }}:</i>
            </div>
            <div class="col-1">
              <div class="field p-fluid">
                <InputSwitch
                  id="notify"
                  :disabled="information.submittedApplication"
                  v-model="information.notifyMe"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <span>* {{ $t("global.mandatoryFields") }}</span>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="text-right">
        <Button
          :label="$t('global.cancel')"
          @click="cancel"
          class="p-button-danger"
        />
        <Button
          :label="$t('global.nextPage')"
          autofocus
          :loading="loading"
          iconPos="right"
          @click="nextPage"
          style="margin-left: 1%; margin-right: 1%"
        />
      </div>
    </template>
  </Card>
</template>

<script>
import {
  required,
  email,
  url,
  minLength,
  maxLength,
  numeric,
} from "@/utilities/i18n-validators";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters } from "vuex";
import { Loader } from "@googlemaps/js-api-loader";
export default {
  setup: () => ({ v$: useVuelidate() }),
  name: "Information",
  emits: ["next-page"],
  data() {
    return {
      submitted: false,
      loadingDropdown: false,
      loading: false,
      loadingValidation: false,
      information: {
        userId: null,
        companyName: "",
        profession: "",
        companyType: "",
        vat: "",
        doy: "",
        address: {
          country: "",
          city: "",
          street: "",
          postalCode: "",
        },
        contact: {
          telephone: "",
          website: "",
        },
        email: "",
        notifyMe: false,
        submittedApplication: false,
        latitude: 0,
        longitude: 0,
      },
      formattedAddress: "",
      validateDate: false,
      map: "",
      marker: {},
      validateButton: "",
      infowindow: null,
      successClasses:
        "p-button p-component animation-color animation-fill-forwards scalein animation-iteration-1 animation-duration-1000 p-button-success",
      errorClasses:
        "p-button p-component animation-color animation-fill-forwards scalein animation-iteration-1 animation-duration-1000 p-button-danger",
      locationSearchValue: "",
      locationResults: [],
    };
  },
  validations() {
    return {
      locationSearchValue: {
        required,
      },
      information: {
        companyName: {
          required,
        },
        profession: {
          required,
        },
        companyType: {
          required,
        },
        vat: {
          required,
          minLength: minLength(9),
          maxLength: maxLength(9),
          numeric,
        },
        doy: {
          required,
        },
        latitude: {
          required,
        },
        longitude: {
          required,
        },
        // address: {
        //   country: {
        //     required,
        //   },
        //   city: {
        //     required,
        //   },
        //   street: {
        //     required,
        //   },
        //   postalCode: {
        //     required,
        //     minLength: minLength(5),
        //     maxLength: maxLength(5),
        //     numeric,
        //   },
        // },
        contact: {
          telephone: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10),
            numeric,
          },
          website: {
            url,
          },
        },
        email: {
          required,
          email,
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "userInfo",
      "clientInfo",
      "countries",
      "companyTypes",
      "doyOptions",
      "cities",
    ]),
  },
  async mounted() {
    this.information = Object.assign(this.information, this.clientInfo);
    this.loadingDropdown = true;
    await Promise.all([
      this.$store.dispatch("countries"),
      this.$store.dispatch("companyTypes"),
      this.$store.dispatch("doy"),
      this.$store.dispatch("cities"),
    ]);

    this.loadingDropdown = false;

    // this.information.address.country = this.countries.find(
    //   (el) => el.alpha2Code == "gr"
    // )?.nameGreek;
    this.validateButton = document.getElementById("validateButton");
    const loader = new Loader({ apiKey: process.env["VUE_APP_GOOGLE_KEY"] });
    await loader.load();
    this.map = new window.google.maps.Map(document.getElementById("map"), {
      center: {
        lat: 37.98381,
        lng: 23.727539,
      },
      zoom: 7,
    });
    console.log(this.information);
    if (this.information.latitude && this.information.longitude) {
      this.locationSearchValue = `${this.information.address.country}, ${
        this.information.address.city
      }${
        this.information.address.street
          ? ", " + this.information.address.street
          : ""
      }`;
      this.$nextTick(() => {
        console.log("create marker");
        const marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            this.information.latitude,
            this.information.longitude
          ),
          animation: window.google.maps.Animation.DROP,
          map: this.map,
        });

        this.map.setZoom(17);
        this.map.panTo(marker.position);
      });
    }
  },
  methods: {
    resetValidation() {
      this.validateButton.className = "p-button p-component";
      this.formattedAddress = "";
      this.information.latitude = 0;
      this.information.longitude = 0;
      let checkIcon = document.querySelector(".checkIcon");
      if (checkIcon) {
        checkIcon.remove();
      }

      if (this.infowindow) {
        this.infowindow.close();
      }
    },
    async validateAddress(input) {
      console.log(input);
      // if (
      //   this.information.address.city &&
      //   this.information.address.street &&
      //   this.information.address.postalCode &&
      //   this.information.address.country
      // ) {
      //   // validateButton.className = "";
      //   // validateButton.className +=
      //   //   "p-button-icon p-button-icon-right animation-color animation-fill-forwards scalein animation-iteration-1
      //   //animation-duration-1000 text-white mdi mdi-18px mdi-check";
      //   let checkIcon = document.querySelector(".checkIcon");

      //   if (checkIcon) {
      //     checkIcon.remove();
      //   }
      //   this.validateButton.className = "";
      //   this.validateButton.className += this.successClasses;

      //   let iconElement = document.createElement("span");
      //   iconElement.className =
      //     "mdi mdi-check p-button-icon p-button-icon-right checkIcon";
      //   this.validateButton.appendChild(iconElement);

      // } else {
      //   await this.v$.$validate();
      //   this.validateDate = true;
      // }
      this.information.latitude = "";
      this.information.longitude = "";
      await this.$store.dispatch("getCoordinates", input.query).then((res) => {
        this.locationResults = res.results;
        console.log(this.locationResults);
      });
    },
    selectLocation({ value }) {
      this.information.address = {
        country: "",
        city: "",
        postalCode: "",
        street: "",
      };

      this.formattedAddress = value.formatted_address;
      this.information.latitude = value.geometry.location.lat;
      this.information.longitude = value.geometry.location.lng;
      if (this.infowindow) {
        this.infowindow.setContent(this.formattedAddress);
      } else {
        this.infowindow = new window.google.maps.InfoWindow({
          content: this.formattedAddress,
        });
      }

      if (this.marker && this.marker.setPosition) {
        this.marker.setPosition(
          new window.google.maps.LatLng(
            this.information.latitude,
            this.information.longitude
          )
        );
        this.marker.setAnimation(window.google.maps.Animation.DROP);
      } else {
        this.marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            this.information.latitude,
            this.information.longitude
          ),
          animation: window.google.maps.Animation.DROP,
          map: this.map,
        });
      }
      this.infowindow.open({
        anchor: this.marker,
        map: this.map,
      });
      this.map.setZoom(17);
      this.map.panTo(this.marker.position);
      value.address_components.forEach((address_component) => {
        if (address_component.types[0] == "route") {
          this.information.address.street = address_component.long_name;

          const streetNumber = value.address_components.find(
            (el) => el.types[0] == "street_number"
          );
          if (streetNumber) {
            this.information.address.street += ` ${streetNumber.long_name}`;
          }
        }

        if (address_component.types[0] == "locality") {
          this.information.address.city = address_component.long_name;
        }

        if (address_component.types[0] == "postal_code") {
          this.information.address.postalCode = Number(
            address_component.long_name.replace(" ", "")
          );
        }

        if (address_component.types[0] == "country") {
          this.information.address.country = address_component.long_name;
        }
      });
      console.log(this.information);
      console.log(this.v$);
    },
    cancel() {
      this.$router.push({ name: "applications" });
    },
    async nextPage() {
      const result = await this.v$.$validate();
      this.submitted = true;
      console.log(result);
      if (result) {
        if (!this.information.submittedApplication) {
          console.log(this.information);
          this.information.userId = this.userInfo.sub;
          this.information.submittedApplication = false;
          this.loading = true;
          if (this.information.id) {
            await this.$store.dispatch("updateClientInfo", this.information);
          } else {
            await this.$store.dispatch("createClientInfo", this.information);
          }
          await this.$store.dispatch("clientInfo", {});
          this.loading = false;
        }
        this.$emit("next-page", { pageIndex: 0, value: 25 });
      }
      // else {
      //   if (
      //     this.v$.information.address.country.$error ||
      //     this.v$.information.address.city.$error ||
      //     this.v$.information.address.street.$error ||
      //     this.v$.information.address.postalCode.$error
      //   ) {
      //     this.validateButton.className = "";
      //     this.validateButton.className += this.errorClasses;
      //     let iconElement = document.createElement("span");
      //     iconElement.className =
      //       "mdi mdi-close p-button-icon p-button-icon-right";
      //     this.validateButton.appendChild(iconElement);
      //   }
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
// .col-2 {
//   width: 12%;
// }

.field {
  margin-bottom: 0;
}

.p-card-body {
  height: 100%;
}

// .p-inputtext,
// .p-dropdown {
//   width: 50%;
//   margin-right: 1%;
// }

// .col {
//   padding: 1% 0;
// }
</style>
